import { loginByPin, logout } from '@swapin-hub/api/auth';

import resetStore from '@/stores/utils/resetStore';
import { queryClient } from '@/queries';

const LOCAL_STORAGE_TOKEN_KEY = 'token';

export default {
  loginError: '',

  getToken() {
    return localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY) || '';
  },

  setToken(token) {
    localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, token);
  },

  removeToken() {
    localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
  },

  loginByPin(credentials) {
    return loginByPin(credentials).then((data) => {
      const { error, token } = data;

      if (error) {
        throw new Error(error);
      }

      if (token) {
        this.setToken(token);
        this.loginError = '';
      }

      return data;
    });
  },

  logout() {
    logout();

    resetStore().all();
    queryClient.clear();

    this.removeToken();

    return Promise.resolve();
  },
};
