import { h } from 'vue';
import { createRouter, createWebHistory, RouterView } from 'vue-router';

import MainView from '../views/MainView.vue';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'main',
      component: MainView,
      children: [
        {
          path: '/',
          name: 'pre-order',
          components: {
            default: () => import('../views/PreOrder/PreOrderPage.vue'),
            header: () => import('../layouts/Header/PreOrderHeader.vue'),
          },
          children: [
            {
              path: 'sell',
              name: 'sell',
              component: () => import('../views/Sell/SellPreOrderPage.vue'),
            },
            {
              path: 'buy',
              name: 'buy',
              component: () => import('../views/Buy/BuyPreOrderPage.vue'),
            },
          ],
        },
        {
          path: 'auth',
          name: 'auth',
          components: {
            default: () => import('../views/Auth/AuthPage.vue'),
            header: () => import('../layouts/Header/DefaultHeader.vue'),
          },
          props: {
            header: {
              title: 'Enter your email',
              canBack: true,
            },
          },
        },
        {
          path: 'auth/pin/:email',
          name: 'auth-pin',
          components: {
            default: () => import('../views/Auth/AuthPinPage.vue'),
            header: () => import('../layouts/Header/DefaultHeader.vue'),
          },
          props: {
            header: {
              title: 'Check your email',
              canBack: true,
            },
          },
        },
        {
          path: 'sell/order',
          name: 'sell-order',
          components: {
            default: () => import('../views/Sell/SellOrderPage.vue'),
            header: () => import('../layouts/Header/DefaultHeader.vue'),
          },
          props: {
            header: {
              title: 'Your order',
            },
          },
        },
        {
          path: 'buy/order',
          name: 'buy-order',
          components: {
            default: () => import('../views/Buy/BuyOrderPage.vue'),
            header: () => import('../layouts/Header/DefaultHeader.vue'),
          },
          props: {
            header: {
              title: 'Your order',
            },
          },
        },
        {
          path: 'buy/order/method',
          name: 'buy-order-method',
          components: {
            default: () => import('../views/Buy/BuyOrderMethodPage.vue'),
            header: () => import('../layouts/Header/DefaultHeader.vue'),
          },
          props: {
            header: {
              title: 'Payment method',
            },
          },
        },
        {
          path: 'buy/order/method/open-banking',
          name: 'buy-order-method-open-banking',
          components: {
            default: () => import('../views/Buy/BuyOrderMethodOpenBankingPage.vue'),
            header: () => import('../layouts/Header/DefaultHeader.vue'),
          },
          props: {
            header: {
              title: 'Make payment',
            },
          },
        },
        {
          path: 'buy/order/method/open-banking/:id',
          name: 'buy-order-method-open-banking-bank',
          components: {
            default: () => import('../views/Buy/BuyOrderMethodOpenBankingBankPage.vue'),
            header: () => import('../layouts/Header/DefaultHeader.vue'),
          },
          props: {
            header: {
              title: 'Make payment',
            },
          },
        },
        {
          path: 'buy/transaction/:id',
          name: 'buy-transaction-details',
          components: {
            default: () => import('../views/Transaction/BuyTransactionDetailsPage.vue'),
            header: () => import('../layouts/Header/TransactionDetailsHeader.vue'),
          },
        },
        {
          path: 'sell/transaction/:id',
          name: 'sell-transaction-details',
          components: {
            default: () => import('../views/Transaction/SellTransactionDetailsPage.vue'),
            header: () => import('../layouts/Header/TransactionDetailsHeader.vue'),
          },
        },
        {
          path: 'verification',
          name: 'verification',
          components: {
            default: () => import('../views/Verification/VerificationPage.vue'),
            header: {
              render() {
                return h(RouterView, { name: 'header' });
              },
            },
          },
          children: [
            {
              path: 'welcome',
              name: 'verification-welcome',
              components: {
                default: () => import('../views/Verification/VerificationWelcomePage.vue'),
                header: () => import('../layouts/Header/DefaultHeader.vue'),
              },
              props: {
                header: {
                  title: 'Welcome to Swapin',
                  canBack: true,
                },
              },
            },
            {
              path: 'details',
              name: 'verification-details',
              components: {
                default: () => import('../views/Verification/VerificationDetailsPage.vue'),
                header: () => import('../layouts/Header/DefaultHeader.vue'),
              },
              props: {
                header: {
                  title: 'Personal details',
                  canBack: true,
                },
              },
            },
            {
              path: 'citizenship',
              name: 'verification-citizenship',
              components: {
                default: () => import('../views/Verification/VerificationCitizenshipPage.vue'),
                header: () => import('../layouts/Header/DefaultHeader.vue'),
              },
              props: {
                header: {
                  title: 'Citizenship',
                  canBack: true,
                },
              },
            },
            {
              path: 'residence-permit',
              name: 'verification-residence-permit',
              components: {
                default: () => import('../views/Verification/VerificationResidencePermitPage.vue'),
                header: () => import('../layouts/Header/DefaultHeader.vue'),
              },
              props: {
                header: {
                  title: 'Residence permit',
                  canBack: true,
                },
              },
            },
            {
              path: 'address',
              name: 'verification-address',
              components: {
                default: () => import('../views/Verification/VerificationAddressPage.vue'),
                header: () => import('../layouts/Header/DefaultHeader.vue'),
              },
              props: {
                header: {
                  title: 'Residential address',
                  canBack: true,
                },
              },
            },
            {
              path: 'identity',
              name: 'verification-identity',
              components: {
                default: () => import('../views/Verification/VerificationIdentityPage.vue'),
                header: () => import('../layouts/Header/VerificationIdentityHeader.vue'),
              },
              props: {
                header: {
                  title: 'Your identity',
                  canBack: true,
                },
              },
            },
            {
              path: 'country-mismatch',
              name: 'verification-country-mismatch',
              components: {
                default: () => import('../views/Verification/VerificationCountryMismatchPage.vue'),
                header: () => import('../layouts/Header/DefaultHeader.vue'),
              },
              props: {
                header: {
                  title: 'Your identity',
                },
              },
            },
            {
              path: 'poa',
              name: 'verification-poa',
              components: {
                default: () => import('../views/Verification/VerificationPoaPage.vue'),
                header: () => import('../layouts/Header/DefaultHeader.vue'),
              },
              props: {
                header: {
                  title: 'Verify your address',
                  canBack: true,
                },
              },
            },
            {
              path: 'financial',
              name: 'verification-financial',
              components: {
                default: () => import('../views/Verification/VerificationFinancialPage.vue'),
                header: () => import('../layouts/Header/DefaultHeader.vue'),
              },
              props: {
                header: {
                  title: 'Financial info',
                  canBack: true,
                },
              },
            },
            {
              path: 'sof',
              name: 'verification-sof',
              components: {
                default: () => import('../views/Verification/VerificationSofPage.vue'),
                header: () => import('../layouts/Header/DefaultHeader.vue'),
              },
              props: {
                header: {
                  title: 'Financial info',
                  canBack: true,
                },
              },
            },
            {
              path: 'confirmation',
              name: 'verification-confirmation',
              components: {
                default: () => import('../views/Verification/VerificationConfirmationPage.vue'),
                header: () => import('../layouts/Header/DefaultHeader.vue'),
              },
              props: {
                header: {
                  title: 'Final confirmations',
                  canBack: true,
                },
              },
            },
            {
              path: 'setting-up-account',
              name: 'verification-setting-up-account',
              components: {
                default: () => import('../views/Verification/VerificationSettingUpAccountPage.vue'),
                header: () => import('../layouts/Header/DefaultHeader.vue'),
              },
              props: {
                header: {
                  title: 'Setting up your account',
                },
              },
            },
            {
              path: 'review',
              name: 'verification-review',
              components: {
                default: () => import('../views/Verification/VerificationOnReviewPage.vue'),
                header: () => import('../layouts/Header/DefaultHeader.vue'),
              },
              props: {
                header: {
                  title: 'Thank you',
                },
              },
            },
            {
              path: 'complete',
              name: 'verification-complete',
              components: {
                default: () => import('../views/Verification/VerificationCompletePage.vue'),
                header: () => import('../layouts/Header/DefaultHeader.vue'),
              },
            },
            {
              path: 'no-service',
              name: 'verification-no-service',
              components: {
                default: () => import('../views/Verification/VerificationNoServicePage.vue'),
                header: () => import('../layouts/Header/DefaultHeader.vue'),
              },
            },
          ],
        },
        {
          path: 'profile',
          name: 'profile',
          components: {
            default: () => import('../views/Profile/ProfilePage.vue'),
            header: () => import('../layouts/Header/DefaultHeader.vue'),
          },
          props: {
            header: {
              title: 'My profile',
              isTitleLeft: true,
            },
          },
        },
        {
          path: 'profile/business',
          name: 'profile-business',
          components: {
            default: () => import('../views/Profile/BusinessProfilePage.vue'),
            header: () => import('../layouts/Header/DefaultHeader.vue'),
          },
          props: {
            header: {
              title: 'Not available yet',
              canBack: true,
            },
          },
        },
        {
          path: '/',
          name: 'limits-main',
          components: {
            default: () => import('../views/Limits/LimitsMainPage.vue'),
            header: {
              render() {
                return h(RouterView, { name: 'header' });
              },
            },
          },
          children: [
            {
              path: 'limits',
              name: 'limits',
              components: {
                default: () => import('../views/Limits/LimitsPage.vue'),
                header: () => import('../layouts/Header/DefaultHeader.vue'),
              },
              props: {
                header: {
                  title: 'My limits',
                  isTitleLeft: true,
                },
              },
            },
            {
              path: 'limits/increase',
              name: 'limits-increase',
              components: {
                default: () => import('../views/Limits/LimitsIncreasePage.vue'),
                header: () => import('../layouts/Header/DefaultHeader.vue'),
              },
              props: {
                header: {
                  title: 'Increase your limits',
                  canBack: true,
                },
              },
            },
            {
              path: 'limits/increase/:level',
              name: 'limits-increase-level',
              components: {
                default: () => import('../views/Limits/LimitsIncreaseLevelPage.vue'),
                header: () => import('../layouts/Header/DefaultHeader.vue'),
              },
              props: {
                header: {
                  title: 'Increase your limits',
                  canBack: true,
                },
              },
            },
            {
              path: 'limits/increase/complete',
              name: 'limits-increase-complete',
              components: {
                default: () => import('../views/Limits/LimitsIncreaseCompletePage.vue'),
                header: () => import('../layouts/Header/DefaultHeader.vue'),
              },
              props: {
                header: {
                  title: 'Limits increased!',
                },
              },
            },
          ],
        },
        {
          path: 'terms',
          name: 'terms',
          components: {
            default: () => import('../views/Terms/TermsPage.vue'),
            header: () => import('../layouts/Header/DefaultHeader.vue'),
          },
          props: {
            header: {
              title: 'Terms and conditions',
              isTitleLeft: true,
            },
          },
        },
        {
          path: 'support',
          name: 'support',
          components: {
            default: () => import('../views/Support/SupportPage.vue'),
            header: () => import('../layouts/Header/DefaultHeader.vue'),
          },
          props: {
            header: {
              title: 'Support',
              isTitleLeft: true,
            },
          },
        },
        {
          path: 'transactions',
          name: 'transaction-history',
          components: {
            default: () => import('../views/TransactionHistory/TransactionHistoryPage.vue'),
            header: () => import('../layouts/Header/DefaultHeader.vue'),
          },
          props: {
            header: {
              title: 'Transaction history',
              isTitleLeft: true,
            },
          },
        },
      ],
    },
    {
      path: '/not-available',
      name: 'not-available',
      component: () => import('../views/Errors/NotAvailablePage.vue'),
    },
    {
      path: '/:pathMatch(.*)*',
      name: '404',
      component: () => import('../views/Errors/NotFoundPage.vue'),
    },
  ],
});

export default router;
